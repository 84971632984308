import React, { useState } from 'react';
import step6 from "./resources/step6.png";
function AboutPage() {
    return (
        <div className="max-w-5xl m-auto py-8 px-2" >
            <h1 className="text-4xl" >About</h1>
            <article className="my-8 uncontrolled">
                <p>
                    GeneSurv is designed for multi-gene cancer survival analysis. Leveraging a comprehensive collection of 94 cancer datasets from the cBioPortal, it provides access to an array of cancer types for research and investigation. Each dataset comprises a minimum of 40 cases, complete with both overall or disease-free survival data, paired with corresponding mRNA expression profiles.
                </p>
                <h1 className="text-2xl font-medium mt-8">Technical Architecture</h1>
                <p>
                    GeneSurv is engineered with a modern, responsive web interface using React.js, ensuring a seamless user experience. The backend, powered by Flask in Python, is responsible for the computation and analysis of data. It performs on-the-fly retrieval of gene expression data from cBioPortal, transforms it into relative percentiles for precise comparisons, and segments the data into Test and Control groups based on user-defined thresholds.
                </p>
                <p>
                    The survival analysis is conducted using the Kaplan-Meier model and the Cox Proportional Hazards model, implemented via the Python lifelines library. This guarantees a robust and reliable statistical analysis, with the results, including survival curves and hazard ratios, presented interactively to the users for in-depth exploration.
                </p>
                <img src={step6} className="shadow m-8 border"></img>

                <h1 className="text-2xl font-medium mt-8">Features and Functionalities</h1>
                <p>
                    GeneSurv expands beyond the scope of traditional single-gene analysis tools by allowing the examination of multiple genes simultaneously. This capability is essential for exploring how combinations of genes may influence patient survival, providing a more detailed understanding of genetic relationships. The 'Mirror Flip' function offers flexibility in defining gene expression thresholds, aiding in the clear delineation of patient groups for study.
                </p>

                <h1 className="text-2xl font-medium mt-8">User Experience and Data Export</h1>
                <p>
                    The intuitive interface facilitates gene selection, threshold adjustments, and survival analysis submissions. For reproducibility, GeneSurv allows researchers to download the analysis configurations, as well as clinical and gene expression data, in JSON and CSV formats, respectively. Kaplan-Meier plots can be downloaded as PNG files, ensuring researchers can replicate and validate their findings.
                </p>

                <h1 className="text-2xl font-medium mt-8">Acknowledgements and Contact</h1>
                <p>
                    This online tool was developed at <a href="https://www.monash.edu/discovery-institute/nguyen-lab">Nguyen Lab</a>. For more information, please contact the lab at <a href="mailto:Lan.K.Nguyen@monash.edu">Lan.K.Nguyen@monash.edu</a>.
                </p>
            </article>
        </div>
    )
}

export default AboutPage;