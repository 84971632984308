import React, { useState } from 'react';
import step1 from "./resources/step1.png";
import step2 from "./resources/step2.png";
import step3 from "./resources/step3.png";
import step45 from "./resources/step45.png";



function GuidePage() {
    return (
        <div className="max-w-5xl m-auto py-8 px-2" >
            <h1 className="text-4xl" >How-To Guide</h1>
            <article className="my-8 uncontrolled">
                <p>The GeneSurv Analysis Workflow is a user-oriented series of steps designed for researchers to review the impact of gene expressions on cancer patient survival outcomes. The workflow is partitioned into three key phases: Configuring the Analysis, Submitting and Comparing Analyses, and Reviewing Results and Data Export.
                </p>
                <img src={step1} className="shadow m-8 border"></img>
                <h2 className="text-2xl font-medium mt-8">Configuring the Analysis</h2>
                <p className="">To begin, select a cancer study from GeneSurv's drop-down menu, with the option to search for studies by typing keywords into the selection box. Upon study selection, the platform will present a detailed description, along with selectable outcomes and gene expression profiles, where defaults are provided and customizable based on the study's data offerings.
                </p>
                <p>
                    For gene configuration, input the HUGO ID of each gene into the search box to create a test group. A minimum of two characters is required to activate the search functionality. Selected genes will appear with an associated slider, which you can use to set the expression threshold for each gene. The slider demarcates the Test criteria with a <span style={{ color: 'blue', fontWeight: 'bold' }}>blue-coloured</span> region and the Control criteria with a <span style={{ color: 'grey', fontWeight: 'bold' }}>grey-coloured</span> region. It's critical to balance the number of genes and threshold strictness to maintain a viable sample size for meaningful analysis.
                </p>
                <img src={step2} className="shadow m-8 border"></img>

                <h2 className="text-2xl font-medium mt-8">Submitting and Comparing Analyses</h2>
                <p className="">
                    With genes and thresholds configured, proceed by clicking the "Submit Analysis" button, which becomes available after a study and at least one gene are selected. The analysis will be processed and displayed on the right side of the interface, where you can conduct side-by-side comparisons of different gene configurations. Should an analysis encounter issues like network errors or insufficient case numbers, it will not display, ensuring only viable results are presented for comparison. 
                </p>
                <img src={step3} className="shadow m-8 border"></img>
                <h2 className="text-2xl font-medium mt-8">Reviewing Results and Data Export</h2>
                <p>
                    After an analysis loads, click on the summary card to view a detailed analysis, including statistical data and Kaplan Meier plots. For further exploration, the configuration used for the analysis can be downloaded as a JSON file, which encompasses all details set during configuration. Additionally, clinical and gene expression data can be obtained in CSV format, and visual results as a PNG of the Kaplan Meier plot. Keeping the JSON file alongside the CSV and PNG ensures the capability to replicate the analysis accurately.
                </p>
                <p>
                    To conclude the review, simply click the "Close" button. This methodical workflow enables the extraction of critical insights from multifaceted genetic data, contributing substantially to the field of cancer prognosis.
                </p>
                <img src={step45} className="shadow m-8 border"></img>
                

                <br></br>
            </article>
        </div>
    )
}

export default GuidePage;