import React, { useState } from 'react';
import homeIntroImage from "./resources/home-intro.png";

function HomePage() {
    return (
        <div className="max-w-5xl m-auto py-8 px-2" >
            <h1 className="text-4xl" >GeneSurv - Multi-Gene Cancer Survival Analysis Tool</h1>
            <article className="my-8 uncontrolled">
                <p className="font-medium">
                    <span className="font-bold">GeneSurv</span> (Multi-Gene Cancer Survival Analysis Tool) is a web-based analytical tool designed to examine the association between <span className="font-bold">gene expression levels</span> across <span className="font-bold">multiple genes</span> and patient <span className='font-bold'>survival outcomes.</span> By enabling the analysis of various gene expressions in relation to patient survival data from publicly accessible clinical studies, users can explore and test the effects of combined gene expression profiles on survival outcomes. Data integration is facilitated by <a href="https://www.cbioportal.org/">cBioPortal for Cancer Genomics</a>, enabling GeneSurv to dynamically access and analyse comprehensive cancer datasets.
                </p>

                <p>
                    To commence your analysis, select the <span className="font-bold">Analysis</span> tab, or for guidance on utilizing GeneSurv, visit the <span className="font-bold">How-To</span> page.
                </p>

                <p>
                    This tool has been developed by the <a href="https://www.monash.edu/discovery-institute/nguyen-lab">Nguyen Lab</a> at
                     the <a href="https://www.monash.edu/discovery-institute">Monash Biomedicine Discovery Institute</a>. For further details on GeneSurv, explore the <span className="font-bold">About</span> page or contact the lab directly.
                </p>

                <p>
                    Below is a sample screenshot of GeneSurv in action:
                </p>
            </article>
            <img src={homeIntroImage} className="shadow border mt-12 mb-4" />
            <div className="italic text-sm text-center w-full mb-8">Example of the analysis page.</div>
        </div>
    )
}

export default HomePage;